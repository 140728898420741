//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LogoBase from '@/components/v2/nav/Logo.vue'
import CityDropdown from '@/components/v2/nav/CityDropdown.vue'
import AnchorText from '@/components/v2/nav/AnchorText.vue'
import SocialMedia from '@/components/v2/nav/SocialMedia.vue'
import CallbackButton from '@/components/v2/nav/Callback.vue'

import elementVisibilityMixin from '~/plugins/elementVisibility'

export default {
  name: 'HeaderMain',
  components: {
    LogoBase,
    CityDropdown,
    AnchorText,
    SocialMedia,
    CallbackButton,
  },
  mixins: [elementVisibilityMixin],
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: process.client ? window.innerWidth : 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      logoFix: require('@/assets/img/v2/logoFix.svg'),
      phone: require('@/assets/img/v2/phone.svg'),
      isMenuActive: false,
      isVisibleFixed: false,
      isShowCheckbox: true,
    }
  },
  computed: {
    mobileHeader() {
      const { isDetail, viewportWidth } = this.detailData
      return (
        (isDetail && viewportWidth <= 1000) ||
        (!isDetail && viewportWidth <= 1151)
      )
    },
    shouldBeActive() {
      return this.isVisibleFixed && this.mobileHeader
    },
  },
  watch: {
    'detailData.viewportWidth'(newVal) {
      if (
        (this.detailData.isDetail && newVal > 1000) ||
        (!this.detailData.isDetail && newVal > 1151)
      ) {
        this.isMenuActive = false
        this.showCheckbox()
      }
    },
  },
  beforeMount() {
    window.addEventListener('wheel', this.handleScroll)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.handleScroll)
    window.removeEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const isHeaderVisible = this.checkElementVisibility('navigation')
      this.isVisibleFixed = !isHeaderVisible
    },
    closeMenu() {
      this.isMenuActive = false
      this.showCheckbox()
    },
    hideCheckbox() {
      this.isShowCheckbox = false
    },
    showCheckbox() {
      this.isShowCheckbox = true
    },
    isMenuItemEnabled(index) {
      switch (index) {
        case 3:
          return !this.$getTextMainSetting('v2IndexHideReviews')
        default:
          return true
      }
    },
  },
}
