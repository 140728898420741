// порядок указан в котором будут разрабатываться страницы
export default {
  main: '#FFD832',
  predprinimatelstvo: '#FF9F41',
  'ege-informatika': '#06BAD5',
  'osnovy-programmirovaniya': '#96F597',
  python: '#2CBB45',
  unity: '#FF4D43',
  'web-razrabotka': '#FF7D1B',
  'game-design': '#A55AF3',
  'graphic-design': '#FFD832',
  videoblogging: '#EB63DF',
  scratch: '#18A0FB',
  'computernaya-gramotnost': '#FFD832',
  frontend: '#AD0C98',
  ai: '#265FDA',
}

export const path = [
  'main',
  'predprinimatelstvo',
  'ege-informatika',
  'osnovy-programmirovaniya',
  'python',
  'unity',
  'web-razrabotka',
  'game-design',
  'graphic-design',
  'videoblogging',
  'scratch',
  'computernaya-gramotnost',
  'frontend',
  'ai',
]

export const colorBackground = {
  main: { baseColor: '', leftLower: '#FFD832', upperRight: '#FFD832' },
  predprinimatelstvo: {
    baseColor: '',
    leftLower: '#FFA243',
    upperRight: '#FFC759',
  },
  'ege-informatika': {
    baseColor: '',
    leftLower: '#0cd1d7',
    upperRight: '#0dee92',
  },
  'osnovy-programmirovaniya': {
    baseColor: '',
    leftLower: '#7EF497',
    upperRight: '#B3F47A',
  },
  python: { baseColor: '', leftLower: '#62DD2E', upperRight: '#A7E438' },
  unity: { baseColor: '', leftLower: '#EB3660', upperRight: '#FD6645' },
  'web-razrabotka': {
    baseColor: '',
    leftLower: '#EB3A34',
    upperRight: '#FF993E',
  },
  'game-design': { baseColor: '', leftLower: '#994FE7', upperRight: '#DD76FB' },
  'graphic-design': {
    baseColor: '',
    leftLower: '#FFBB33',
    upperRight: '#FFED41',
  },
  videoblogging: { baseColor: '', leftLower: '#FA51F1', upperRight: '#FC74FF' },
  scratch: {
    baseColor: '',
    leftLower: '#34ECF6',
    upperRight: '#28C0FF',
  },
  'computernaya-gramotnost': {
    baseColor: '',
    leftLower: '#FFC135',
    upperRight: '#FFEC41',
  },
  frontend: { baseColor: '', leftLower: '#D90CC7', upperRight: '#9A0DEE' },
  ai: { baseColor: '', leftLower: '#265FDA', upperRight: '#50ACFF' },
}
