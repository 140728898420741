//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* элементы страницы */

import HeaderMain from '@/components/v2/Header.vue'
import FirstSteps from '@/components/v2/FirstSteps.vue'
import ApplicationForm from '@/components/v2/ApplicationForm.vue'
import Teachers from '@/components/v2/Teachers.vue'
import FAQ from '@/components/v2/FAQ.vue'
import FooterMain from '@/components/v2/Footer.vue'

import ProgramButton from '@/components/v2/main/ProgramButton.vue'
import TrustSection from '@/components/v2/main/TrustSection.vue'
import ItGoals from '@/components/v2/main/ItGoals.vue'
import LongTermLearning from '@/components/v2/main/LongTermLearning.vue'
import Platform from '@/components/v2/main/Platform.vue'
import BtwSection from '@/components/v2/main/BtwSection.vue'
import TestimonialsCustomers from '@/components/v2/main/Testimonials.vue'
import MapAddresses from '@/components/v2/main/Map.vue'

import WhatWeOffer from '@/components/v2/detail/WhatWeOffer.vue'
import WillDo from '@/components/v2/detail/WillDo.vue'
import Result from '@/components/v2/detail/Result.vue'
import FormatOfClasses from '@/components/v2/detail/FormatOfClasses.vue'

/* всё остальное */
import CallMeBack from '@/components/v2/pop-up/CallMeBack.vue'
import CookiePopup from '@/components/v2/pop-up/Cookies.vue'
// import LocationPrompt from '@/components/v2/pop-up/LocationPrompt.vue'
import RequestSuccess from '@/components/v2/pop-up/RequestSuccess.vue'
import SelectYourArea from '@/components/v2/pop-up/SelectYourArea.vue'

import viewport from '~/plugins/viewport.js'
import colors, { colorBackground } from '~/const/colorPath.js'
import currentPathMixin from '~/plugins/currentPathMixin.js'
import i18nText from '~/locales/ru/ru.js'
import { allKeysOfTexts } from '~/const/keyPath.js'

import mainData from '~/locales/ru/main.json'
import egeInformatikaData from '~/locales/ru/ege-informatika.json'
import computernayaGramotnostData from '~/locales/ru/computernaya-gramotnost.json'
import gameDesignData from '~/locales/ru/game-design.json'
import graphicDesignData from '~/locales/ru/graphic-design.json'
import osnovyProgrammirovaniyaData from '~/locales/ru/osnovy-programmirovaniya.json'
import predprinimatelstvoData from '~/locales/ru/predprinimatelstvo.json'
import pythonData from '~/locales/ru/python.json'
import unityData from '~/locales/ru/unity.json'
import videobloggingData from '~/locales/ru/videoblogging.json'
import scratchData from '~/locales/ru/scratch.json'
import frontendData from '~/locales/ru/frontend.json'
import webRazrabotkaData from '~/locales/ru/web-razrabotka.json'
import aiData from '~/locales/ru/ai.json'

export default {
  name: 'BasePageWrapper',
  components: {
    HeaderMain,
    FirstSteps,
    ApplicationForm,
    Teachers,
    FAQ,
    FooterMain,

    ProgramButton,
    TrustSection,
    ItGoals,
    LongTermLearning,
    Platform,
    BtwSection,
    TestimonialsCustomers,
    MapAddresses,

    WhatWeOffer,
    WillDo,
    Result,
    FormatOfClasses,

    CallMeBack,
    CookiePopup,
    // LocationPrompt,
    RequestSuccess,
    SelectYourArea,
  },
  mixins: [viewport, currentPathMixin],
  props: {
    pageName: {
      type: String,
      default: 'main',
    },
    pageKey: {
      type: String,
      default: 'main',
    },
    isDetailPage: {
      type: Boolean,
      default: true,
    },
    componentOrder: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isPopUpVisible: false,
      baseColor: colors[this.pageName],
      backgroundColor: colorBackground[this.pageName],

      mainData,
      computernayaGramotnostData,
      egeInformatikaData,
      gameDesignData,
      graphicDesignData,
      osnovyProgrammirovaniyaData,
      predprinimatelstvoData,
      scratchData,
      frontendData,
      pythonData,
      unityData,
      videobloggingData,
      webRazrabotkaData,
      aiData,
    }
  },
  computed: {
    // locales() {
    //   return this.$i18n.locales
    // },
  },
  watch: {
    routePath() {
      this.updateBaseColor()
    },
  },
  mounted() {
    this.updateBaseColor()
  },
  methods: {
    // switchLanguage(locale) {
    //   this.$router.push(this.switchLocalePath(locale))
    // },
    updateBaseColor() {
      this.baseColor = colors[this.routePath]
    },
    handleClickPopUp() {
      this.isPopUpVisible = false
    },
    handleClickPopUpSelectYourArea() {
      this.isPopUpVisible = true
    },

    generateDetailData(sectionKey) {
      // console.log(
      //   `this.generateSettings(this.pageKey, ${sectionKey}): `,
      //   this.generateSettings(this.pageKey, sectionKey),
      //   this.pageKey.length > 0,
      //   sectionKey.length > 0
      // )
      return {
        routePath: this.routePath,
        isDetail: this.isDetailPage,
        viewportWidth: this.viewportWidth,
        // i18n
        // data:
        // this.pageKey.length > 0 && sectionKey.length > 0
        //   ? this.$t(`${this.pageKey}.${sectionKey}`)
        //   : '',
        // json
        // data:
        //   this.pageKey.length > 0 && sectionKey.length > 0
        //     ? this[`${this.pageKey}Data`][sectionKey]
        //     : '',
        // backEnd
        data:
          this.pageKey.length > 0 && sectionKey.length > 0
            ? this.generateSettings(this.pageKey, sectionKey)
            : '',
      }
    },
    getDetailDataForComponent(componentName) {
      switch (componentName) {
        case 'HeaderMain':
          return this.generateDetailData('Header')
        // Добавьте сюда другие исключения, если потребуется
        default:
          return this.generateDetailData(componentName)
      }
    },
    hexToRgba(hex, alpha) {
      let r = 0
      let g = 0
      let b = 0
      if (hex && hex.length > 5) {
        // Удаляем "#" если оно есть
        if (hex.length === 4) {
          r = parseInt(hex[1] + hex[1], 16)
          g = parseInt(hex[2] + hex[2], 16)
          b = parseInt(hex[3] + hex[3], 16)
        } else if (hex.length === 7) {
          r = parseInt(hex[1] + hex[2], 16)
          g = parseInt(hex[3] + hex[4], 16)
          b = parseInt(hex[5] + hex[6], 16)
        }
        if (alpha) {
          return `rgba(${r}, ${g}, ${b}, ${alpha})`
        } else {
          return `rgb(${r}, ${g}, ${b})`
        }
      } else {
        return `#FFD832`
      }
    },

    generateSettings(pageKey, sectionKeyEl) {
      const defaultSettings = i18nText[pageKey][sectionKeyEl]
      const allKeysOfTextsSection = allKeysOfTexts[sectionKeyEl]

      const replaceValuesWithSettings = (
        allKeysOfTextsSection,
        defaultSettings,
        currentPath = ''
      ) => {
        const getTextSetF = (dbKey) => this.$getTextSetting(dbKey)
        const getTextMainSetF = (dbKey) => this.$getTextMainSetting(dbKey)
        const getBullets = (dbKey) => this.$getBullets(dbKey)
        const getVenues = (dbKey) => this.$getVenues(dbKey)

        if (Array.isArray(allKeysOfTextsSection)) {
          // Обработка массива
          return allKeysOfTextsSection.map((item, index) => {
            if (Array.isArray(item) && item.length === 2) {
              const [indexValue, dbKey] = item
              if (indexValue === 0) {
                // Вернуть конкретный элемент массива по текущему пути
                return getValueFromDefaultSettings(
                  defaultSettings,
                  `${currentPath}[${index}]`
                )
              } else if (indexValue === 1) {
                return getTextSetF(dbKey)
              } else if (indexValue === 2) {
                return getTextMainSetF(dbKey)
              }
            } else if (typeof item === 'object' && item !== null) {
              // Рекурсия для объектов внутри массива
              return replaceValuesWithSettings(
                item,
                defaultSettings,
                `${currentPath}[${index}]`
              )
            } else {
              return item
            }
          })
        } else if (
          typeof allKeysOfTextsSection === 'object' &&
          allKeysOfTextsSection !== null
        ) {
          return Object.keys(allKeysOfTextsSection).reduce((acc, key) => {
            const newPath = currentPath ? `${currentPath}.${key}` : key
            if (
              Array.isArray(allKeysOfTextsSection[key]) &&
              (allKeysOfTextsSection[key].length === 2 ||
                (allKeysOfTextsSection[key].length === 3 &&
                  (allKeysOfTextsSection[key][2] === 'bullets' ||
                    allKeysOfTextsSection[key][2] === 'venues')))
            ) {
              const [indexValue, dbKey, keyType] = allKeysOfTextsSection[key]
              if (indexValue === 0) {
                acc[key] = getValueFromDefaultSettings(defaultSettings, newPath)
              } else if (indexValue === 1) {
                switch (keyType) {
                  case 'bullets':
                    acc[key] = getBullets(dbKey)
                    break
                  case 'venues':
                    acc[key] = getVenues(dbKey)
                    break
                  default:
                    acc[key] = getTextSetF(dbKey)
                }
              } else if (indexValue === 2) {
                acc[key] = getTextMainSetF(dbKey)
              }
            } else if (
              typeof allKeysOfTextsSection[key] === 'object' &&
              allKeysOfTextsSection[key] !== null
            ) {
              acc[key] = replaceValuesWithSettings(
                allKeysOfTextsSection[key],
                defaultSettings,
                newPath
              )
            } else {
              acc[key] = allKeysOfTextsSection[key]
            }
            return acc
          }, {})
        }
        return allKeysOfTextsSection
      }

      function getValueFromDefaultSettings(defaultSettings, path) {
        const keys = path.split('.').reduce((acc, part) => {
          const arrayMatch = part.match(/(\w+)\[(\d+)\]/)
          if (arrayMatch) {
            acc.push(arrayMatch[1])
            acc.push(Number(arrayMatch[2]))
          } else {
            acc.push(part)
          }
          return acc
        }, [])

        return keys.reduce((obj, key) => obj && obj[key], defaultSettings)
      }

      return replaceValuesWithSettings(allKeysOfTextsSection, defaultSettings)
    },
  },
}
